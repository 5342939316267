@font-face {
  font-family: StarWars;
  src: url(../Starjedi.ttf) !important;
}
#bg {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}
#bg2 {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}
.ndcWI {
  position: relative;
  z-index: 150 !important;
  text-align: center;
  width: 60%;
  color: white;
  max-height: 100px;
  margin: 1rem auto;
  transform: perspective(50px) rotate3d(-10, 0, 0, -2deg);
  transform-origin: center center;
}
.title {
  font-size: 7vw;
  line-height: 1.4;
  font-weight: normal;
  margin-bottom: 1.5rem;
}
.tilted__text{
  font-size: 3vw;
}
.article {
  position: relative;
  z-index: 150 !important;
  display: block;
  font-size: 2.5rem !important;

  animation: 25s linear 0s infinite normal none running sw !important;
}

@keyframes sw {
  0% {
    transform: translate(0px, 300px);
  }
  100% {
    transform: translate(0px, -150%);
  }
}
.evAkJH {
  margin: 0px auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  z-index: 150;
  padding: 1rem;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}
.launch-button {
  border: none;
  outline: none;
  z-index: 100000 !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 70%;
  cursor: pointer;
}
.launch-button {
  background: transparent;
  position: fixed;
}
.switchImg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
}
.helbHF {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  --navbar-top-space: 0;
  padding-top: 101px;
  
}
.launch {
  border: none;
  outline: none;
  z-index: 100000 !important;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 70%;
  color: white;
 
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .article {
    font-size: 1em;
  }
  .ndcWI {
    width: 100%;
  }
  .article {
    position: relative;
    z-index: 150 !important;
    display: block;
    font-size: 1.5rem !important;

    animation: 10s linear 0s infinite normal none running sw2 !important;
  }
  .tilted__text{
    font-size: 5vw;
  }
  @keyframes sw2 {
    0% {
      transform: translate(0px, 200px);
    }
    100% {
      transform: translate(0px, -250%);
    }
  }
}
