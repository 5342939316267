body {
  background-image: url(./bkg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.explore__title {
  color: white;
  font-size: 40px;
  line-height: 70px;
}
.explore__text {
  color: white;
  font-size: 20px;
  line-height: 35px;
}
.explore__text2 {
  color: white;
  font-size: 15px;
  line-height: 25px;
}
.explore__btn__img {
  position: relative;
  cursor: pointer;
}
.explore__btn__img__text {
  cursor: pointer;
  font-size: 1.1rem;
  position: relative;
  bottom: 2.5rem;
  left: 2rem;
  color: #fff;
}
.explore__ack {
  color: #fe7a2b;
  font-weight: bolder;
}
.explore__bkg{

}