/* .navbar{
    border:1px solid red;
    color: white;
    background-color: grey;
    position: fixed;
} */

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.navbar {
  width: 100%;
  background-color: transparent;

  display: flex;
  justify-content: space-between !important;
  font-size: 1.2rem;
  z-index: 1000000000;
}

.nav-container {
  display: flex;

  width: 100%;
  justify-content: space-between !important;
  align-items: center;
}

.main-container {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  position: relative;

  top: 1rem;
  align-items: center;
  color: #75e6e4;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 5rem;
}

.nav-links {
  text-decoration: none;
  border-bottom: 3px solid transparent;
  color: #ffff !important;
  font-family: header;
  text-align: center;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  color: #ffff !important;
  text-align: center !important;
}
.nav-item-btn {
  margin-top: 1.3rem;
}
.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
  display: none;
}
.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: white;
}
@media screen and (max-width: 800px) {
  .nav-menu {
    display: none;
  }
  .navbar{

text-align: left;
  }
  /* .navbar {
    margin-right: 1rem !important;
  } */
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-bottom: 1rem;
    top: 50px;
    right: 0rem;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: all 0.5s ease;
    text-align: left;
  }

  .nav-links {
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 1.3rem;
    right: 0.8rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    top: 1.8rem;
    right: 1.3rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 100;
  }

  .nav-item {
    background: #75e6e4;
    border: 2px solid #000000;
    color: #000000 !important;
    text-align: center !important;
    padding: 0.2rem;
  }

  .nav-logo {
    position: relative;
    top: 0rem;
    right: 1.4rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
/* --------------------------------------------------------------------------------------- */
