.planet_1 {
  position:absolute;                 
  bottom:0;                         
 right:0; 
  z-index: -10;
}
.planet_2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -10;
}
.typewriter {
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  font-size: 20px;

  line-height: calc(17px + 15px);
  font-weight: normal;
  margin-bottom: 1rem;
  font-weight: bolder;
}
.subscribe__text{
  color: white;
  font-weight: lighter !important;
}
a {
  text-decoration: none;
  color: #fff;
}
.social1 {
  text-align: right;
  padding: 2rem;
}
.social2 {
  text-align: left;
  padding: 2rem;
  position: relative;
  z-index: 10000000;
}
.subscribe__input {
  background-color: #fff;
  color: black;
  width: 30vw;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.5rem;
}
.subscribe__btn {
  padding: 1rem;
  background-color: black;
  text-align: center;
  color: white;
}
.subscribe__btn__img {
  position: absolute;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
}
.subscribe__btn__img__text {
  padding: 1rem;
  position: relative;
  color: white;
  z-index: 100;
  bottom: 1px;
}

.susbscribe__2nd__text {
  --text-lg: 24px;
  margin-bottom: 1.5rem;
  font-size: var(--text-lg);
  font-weight: lighter;
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media screen and (max-width: 800px) {
  .planet_1 {
   display: none;
  }
  .planet_2 {
    display: none;
  }
  .social1 {
    text-align: center;
  }
  .social2 {
    text-align: center;
    margin-top: -2.3rem !important;
  
  }
  .subscribe__input {

    width: 50vw;

  }
}
