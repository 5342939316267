.planet__right {
  position: relative;
  right: 3rem;
  width: 32vw;
  height: 70vh;
}
.card__left {
  position: relative;
  left: 1rem;
}
.content {
  text-align: left;
  color: white;
  position: relative;
  /* width: fit-content; */
  top: 5vw;
  left: 4.5vw;
  width: 90%;
 height: 70vh;
  background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px);
}
.axie__header {
  text-align: center;
  background-color: rgb(117, 117, 247);
  border-radius: 10px;
  color: black;
  padding: 0.3rem;
  margin: 0.5rem;
}
.top__of__planet {
  position: relative;
  right: 3rem;
}
.top__of__planet__text {
  color: white;
  position: relative;
  right: 3rem;
  bottom: 2.5rem;
}
.grid__bg{
  background-image: url(./Union.png);
  background-size:cover;
 width: 100%;
 height: 100vh;
 position: relative;
left: 8vw;

}
.planet__grid{
position: relative;
right: 2vw;
}

.metaverse__btn__img__individual {
  position: relative;
  cursor: pointer;

  z-index: 100000;
}
.metaverse__btn__img__text__individual {
  cursor: pointer;
  font-size: 1.1rem;
  position: relative;
  bottom:6vh;
  z-index: 100000;
  color: #fff;
}
.container{
  display: flex;
}
.content2{
flex: 1;
margin: 0;
position: absolute;
top: 67%;
left:30%;
text-align: left;
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);

}
h1{
  color: #FE7A2B;
}
h2{
  color: white;
}
@media screen and (max-width: 800px) {
  .container{
    display: flex;
    position: relative;
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
 
  }
  .content3{
    display: flex;
  justify-content: center;
  flex-direction: column;
    text-align: center;
  flex: 1;
 
  }
  h1{
    font-size: 5.5vw;
  }
.container__bkg{
position: relative;
}
  .content2{
   /* padding: 1rem; */
  /* width: 75vw;

   overflow-y: scroll;
    display: flex;
  justify-content: center;
  flex-direction: column;
    text-align: left;
  flex: 1;
  position: absolute;
  top: 45%;
  height: fit-content;
  background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px); */
  background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px); 
  margin: 0;
  position: absolute;
  top: 67%;
  left: 52%;
  text-align: left;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 63vh;
overflow-y: scroll;
  
  }

  .content {
    text-align: left;
    color: white;
    position: relative;
    width: 100%;
    height: 100%;
    top: 5vw !;
    margin-top: 1rem;
    left: 8.5vw;
    width: 90%;
    height: 85vh;
    padding-left: 2rem;
    background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px);
  }
  .grid__bg{
    background-image: url(./Vector.png);
    background-size:cover;

  
  }
  .planet__right {
    position: relative;
   
    left: 60%;
    transform: translateX(-50%);
    width: 80vw;
    height: 40vh;
  }
  .lineH{
    width: 70vw;
  }
  .planet__grid{
    position: relative;
    right: 0vw;
    }
    .know__more{
      position: relative; 
      left: 65%;
      transform: translateX(-50%);
    }
    .planet__responsive{
      position: relative; 
      left: 77%;
      transform: translateX(-50%);
    }
    
}
@media screen and (max-width: 400px) {
  .content2{

    background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px); 
    margin: 0;
    position: absolute;
    top: 67%;
    left: 52%;
    text-align: left;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 68vh;
  overflow-y: scroll;
    
    }
}
@media screen and (width: 320px) {
  .content2{

    background: rgba(21, 4, 47, 0.4);
backdrop-filter: blur(20px); 
    margin: 0;
    position: absolute;
    top: 67%;
    left: 52%;
    text-align: left;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 78vh;
  overflow-y: scroll;
    
    }
}
