.team__header{
    color: white;
    font-size: 3vw;
    background-color: white;
    padding: 1rem;
      background: rgba(255, 255, 255, 0.12);
  
}
.align{
    vertical-align: text-top;
    color: white;
}
.img__align{
    position: relative;
    top:6vh;
}
.team__img{
 
}
.teams{

}


  /* .ellipse {
    width:28vw;
    height: 15vh;
    border-radius: 50%;
    -ms-transform: rotate(40deg);  
    -webkit-transform: rotate(40deg);  
    transform: rotate(30deg);
    position: absolute;
    left: 2vw;
    top: 9vh;
    font-size: 5em;
    color: #fff;
    text-align: center;
    letter-spacing: 5px;
    background: #020117;
  }
  .ellipse::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2);
    z-index: -1;
  }
  .ellipse::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2);
    z-index: -2;
    filter: blur(40px);
  } */

.img__align:hover {
   
    position: relative;

    /* animation: zoom-in-zoom-out 1s ease infinite; */
    
  }
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }
  @keyframes animate {
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }
    100% {
     transform: perspective(1000px) rotateY(360deg);
    }
}
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }

  }
.teams{
    padding: 5rem;
}
  @media screen and (max-width:600px) {
    .img__align{
        position: relative;
        top:2vh;
    }
    .team__header{
    
        font-size: 5vw;
        font-weight: bolder;
        
    }
    body {
        overflow-x: hidden;   
        width: 100%;
      }

      .ellipse {
    display: none;
     
      }
      .teams{
          padding: 1rem;
      }
  }

  
*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    
    
}

body {
  overflow-x: hidden;   
  width: 100%;
}

.shapes {
    overflow: hidden;
}
.shapes .rocket {
    transform: rotate(113deg);
    z-index: 100 !important;
    
}
.shapes .rocket-holder {
    animation:up-down infinite ;    
    animation-direction: alternate;
    animation-duration: 150ms;
    z-index: 100 !important;
    position: absolute;
    top: 50%;
  left:30%;
}

 @keyframes up-down {
    100% {transform: translateY(0px);}
   0% {transform: translateY(2px);}
} 
.shapes .shape0 {
    position: absolute;
    background: beige;
    border-radius: 80%;
    z-index: 8;
    right: -2000px;
    animation: rocket ;
    animation-iteration-count: 3;

}



.shapes .shape1 {
    height: 3px;
    width: 30px;   
    top: 50%;
 
    animation-duration: 4s;
    animation-delay: 700ms;  
}
.shapes .shape2 {
    height: 3px;
    width: 60px;
    top: 60%;  
    
    animation-duration: 4s;
    animation-delay: 500ms;  
}

.shapes .shape3 {
    height: 3px;
    width: 30px;
    top: 45%;  
   
    animation-duration: 4s;
    animation-delay: 150ms;

}


.shapes .shape4 {
    height: 3px;
    width: 10px;
    top: 60%;    
    
    animation-duration: 4s;
    animation-delay: 100ms;
}

.shapes .shape5 {
    height: 3px;
    width: 30px;
    top: 50%;    
    
    animation-duration: 4s;
    
}


@keyframes rocket {
    0% {transform:translateX(0px)}
    100% {transform:translateX(-4000px)}
}




.planets {
    position: absolute;
     right: -2000px; 
     z-index: -1;
     animation: rocket ;
    animation-iteration-count: 1;
    animation-duration: 15s;  
   
}

.planet1 {
  
    top:20%;    
    animation-delay: 50ms;
}
.planet2 {
    
    top:2%;   
    animation-delay: 2s;
}
.planet4 {
    
    bottom:5%;
    animation-delay: 3s;
}

.planet5 {
    
    bottom:30%;
    animation-delay: 5s;
}

.planet6 {
    
    top:20%;
    animation-delay: 8s;
}
.planet7 {
    
    bottom:30%;
    animation-delay: 9s;
}
.planet8 {
    width: 400px;
    bottom:-30%;
    left: -1000;
    animation-duration:40s ;
    animation-delay: 1s;
}
.planet9 {
    
    bottom:10%;
    animation-delay: 6s;
}



.final {
    position: absolute;
    top: 45%;
    left: 50%;
    animation:final 25s normal ;
    z-index: 100;
    /* animation-delay: 15s; */
}

@keyframes final {
    0% {transform:translateX(5000px)}
    100% {transform:translateX(0px)}
}


.final img {
    position: absolute;
    
} 

.final .human {
    width: 100px;
    top: 0px;
    animation:up-down-final  infinite ;    
    animation-direction: alternate;
    animation-duration: 1s;
    
}
.final .planet10 {
    animation:up-down-final  infinite ;    
    animation-direction: alternate;
    animation-duration: 2s;
    bottom: 20%;
}
.final .planet11 {
    animation:up-down-final  infinite ;    
    animation-direction: alternate;
    animation-duration: 2s;
    animation-delay: 2s;
   top: 10%;
}

@keyframes up-down-final {
    100% {transform: translateY(0px);}
   0% {transform: translateY(10px);}
} 


  
  /* Here using -webkit, we make angle
  of 45 degree of falling star */
  .sky {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  }
  
  /* Here we are making roundabout balls */
  .star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  border-radius: 885px;
  background: linear-gradient(-45deg,
    #eef0f5, rgba(0, 0, 255, 0));
  
  -webkit-filter: drop-shadow(0 0 6px #eef1f8);
  filter: drop-shadow(0 0 6px #d7dff0);
  -webkit-animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
  animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
  }
  
  /* Here we add before and after effect to star */
  .star::before,
  .star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 255, 0),
    #eaeef8,
    rgba(0, 0, 255, 0)
  );
  -webkit-transform: translateX(50%) rotateZ(45deg);
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
  }
  .star::after {
  -webkit-transform: translateX(50%) rotateZ(-45deg);
  transform: translateX(50%) rotateZ(-45deg);
  }
  
  /* Here we are adding location of each
  nth-child, animations, delays, and
  before and after effects to each
  and every balls(stars) */
  .star:nth-child(1) {
  top: calc(50% - -119px);
  left: calc(50% - 43px);
  -webkit-animation-delay: 4000ms;
  animation-delay: 4000ms;
  }
  .star:nth-child(1)::before,
  .star:nth-child(1)::after {
  -webkit-animation-delay: 4000ms;
  animation-delay: 4000ms;
  }
  .star:nth-child(2) {
  top: calc(50% - -43px);
  left: calc(50% - 37px);
  -webkit-animation-delay: 5000ms;
  animation-delay: 5000ms;
  }
  .star:nth-child(2)::before,
  .star:nth-child(2)::after {
  -webkit-animation-delay: 5000ms;
  animation-delay: 5000ms;
  }
  .star:nth-child(3) {
  top: calc(50% - -40px);
  left: calc(50% - 222px);
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  .star:nth-child(3)::before,
  .star:nth-child(3)::after {
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  .star:nth-child(4) {
  top: calc(50% - -29px);
  left: calc(50% - 113px);
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  .star:nth-child(4)::before,
  .star:nth-child(4)::after {
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  .star:nth-child(5) {
  top: calc(50% - 146px);
  left: calc(50% - 112px);
  -webkit-animation-delay: 3000ms;
  animation-delay: 3000ms;
  }
  .star:nth-child(5)::before,
  .star:nth-child(5)::after {
  -webkit-animation-delay: 3000ms;
  animation-delay: 3000ms;
  }
  .star:nth-child(6) {
  top: calc(50% - -108px);
  left: calc(50% - 160px);
  -webkit-animation-delay: 3000ms;
  animation-delay: 3000ms;
  }
  .star:nth-child(6)::before,
  .star:nth-child(6)::after {
  -webkit-animation-delay: 3000ms;
  animation-delay: 3000ms;
  }
  .star:nth-child(7) {
  top: calc(50% - 52px);
  left: calc(50% - 72px);
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  .star:nth-child(7)::before,
  .star:nth-child(7)::after {
  -webkit-animation-delay: 7000ms;
  animation-delay: 7000ms;
  }
  
  /* This code will help to generate
  effect in tail of star */
  @keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
  }
  
  /* This code will generate shining
  effect in head of star */
  @keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
  }
  
  /* Here we are adding webkit-animation
  to head of star for shining */
  @-webkit-keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
  }
  
  /* Here we are adding webkit-animation
  to tail of star */
  @-webkit-keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
  }
  
  /* Here we are adding webkit-animation
  for shooting effect */
  @-webkit-keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(270px);
    transform: translateX(270px);
  }
  }
  
  /* Here we make shooting effect */
  @keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(270px);
    transform: translateX(270px);
  }
  }
  